.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: rgb(239, 238, 238);
  padding: 20px;
  border-radius: 8px;
  max-width: 950px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.aguia-preso-detalhe {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.aguia-preso-imagem {
  width: 150px;
  height: 150px;
  border-radius: 15px;
  object-fit: cover;
  margin-bottom: 10px;
}

.campos-comuns h2 {
  margin-top: 20px;
  font-size: 1.5em;
  color: #333;
}

.aguia-card-presos {
  border: 1px solid #9d9c9c;
  padding: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.aguia-card-img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-right: 20px;
  object-fit: cover;
}

.aguia-card-detalhes {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.aguia-card-detalhes div {
  margin-bottom: 5px;
}

.aguia-card-detalhes strong {
  width: 100px;
  display: inline-block;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px; /* Ajuste o tamanho conforme necessário */
}

.aguia-h1 {
  margin: 0;
  font-size: 48px;
  margin-right: 10px; /* Espaço entre o texto e a logo */
  margin-left: 10px;
  font-family: 'Lato', sans-serif;
  color: #212020; /* Texto preto */
  letter-spacing: 3px;
  /* Borda dourada ao redor do texto */
  text-shadow: 
    -1px -1px 0 #edc8a6,  
     1px -1px 0 #edc8a6,
    -1px  1px 0 #edc8a6,
     1px  1px 0 #edc8a6;
}

.aguia-card {
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  width: 100%;
  padding: 10px;
  display: flex;
  gap: 20px; /* Espaçamento entre a imagem e os dados */
  overflow: hidden;
  transition: transform 0.3s ease;
    border: 1px solid #9d9c9c;

}

.aguia-card:hover {
  transform: scale(1.02); /* Efeito de zoom suave ao passar o mouse */
}

.aguia-image-preview {
  height: 150px;
  width: 150px;
  border-radius: 12px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-bottom: 12px solid transparent;
  transition: border-color 0.3s ease, transform 0.3s ease;
}

.aguia-image-preview:hover {
  transform: scale(1.05);
}

.aguia-info-section {
  flex-grow: 1; /* Ocupar o restante do espaço */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ajusta a distribuição vertical */
  text-align: left;
}

.aguia-info-section label {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
}

.aguia-info-section h2 {
  font-size: 24px;
  color: #333;
  margin: 0;
}

.aguia-detail-row {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

.aguia-detail-row div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.aguia-detail-row h2 {
  font-size: 18px;
  color: #333;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .aguia-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .aguia-info-section {
    text-align: center;
  }

  .aguia-detail-row {
    flex-direction: column;
    gap: 10px;
  }
}

.header-container {
  display: flex;
  align-items: center; /* Alinha a imagem e o texto verticalmente */
  gap: 10px; /* Espaçamento entre a imagem e o título */
  justify-content: center;
}

.aguia-title-container {
  display: flex;
  flex-direction: column; /* Coloca o subtítulo abaixo do título */
}

.h1 {
  margin: 0;
  font-size: 50px;
  margin-right: 10px; /* Espaço entre o texto e a logo */
  margin-left: 10px;
  font-family: 'Lato', sans-serif;
  color: #212020; /* Texto preto */
  letter-spacing: 11px;
  /* Borda dourada ao redor do texto */
  text-shadow: 
    -2px -2px 0 #edc8a6,  
     1px -1px 0 #edc8a6,
    -1px  1px 0 #edc8a6,
     1px  1px 0 #edc8a6;
}

.subtitle {
  font-size: 1.0rem;
  color: #66666653; /* Ajuste a cor do subtítulo conforme necessário */
  margin: 5px 0 0; /* Espaçamento entre o título e o subtítulo */
  margin-left: -6px;
  margin-top: -4px;
  letter-spacing: 1px;
}

.subtitle2 {
  text-align: left;
  font-size: 1.0rem;
  color: #1615155e; /* Ajuste a cor do subtítulo conforme necessário */
  margin: 5px 0 0; /* Espaçamento entre o título e o subtítulo */
  margin-left: -6px;
  margin-top: -4px;
  margin-left: 10px;
  margin-bottom: 4px;
}
.hr-dotted {
  border: none;
  border-top: 1px dotted #ccc; /* Cor cinza clara e estilo pontilhado */
  margin: 20px 0;              /* Espaçamento superior e inferior */
  width: 100%;                 /* Largura total da linha */
}