.footer {
    width: 100%;
    padding: 11px;
    background-color: #253221;
    color: #f4c497;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    font-family:'arial' ;
   
  }
  
  .footer p {
    margin: 0;
    font-size: 12px;
  }