body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
  }
  
  .container {
    width: 100%;
    margin: auto;
  }
  
  
  
  .search-bar {
    margin-top: 120px;
    display: flex;
    justify-content: center;
  }
  
  .search-bar input {
    padding: 10px;
    width: 68%;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: -100px;
    margin-top: -100px;
  }
  
  .btn {
    padding: 10px 10px;
    margin-left: 10px;
    background-color: #8a9175;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 150px;
  }
  
  .btn:hover {
    background-color: #5b604c;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    
    
  }
  
  .table th, .table td {
    padding: 4px;
    border-bottom: 1px solid #bdbaba58;
    text-align: center;
    margin-top: 10px;
  }
  
  .table th {
   text-align: center;
    
    color: #1312123d;
  }
  
  .table tr:hover {
    background-color: #f1f1f1;
  }
  
  @media (max-width: 768px) {
    .search-bar input {
      width: 100%;
    }
  
    .table {
      font-size: 14px;
    }
  }
  
  .image-container {
    text-align: center; /* Centraliza a imagem e o score */
  }
  
  .profile-image {
    width: 60px;
    height: 60px;
    border-radius: 6%;
    object-fit: cover;
    margin-top: 10px;
  }
  
  .score-label {
    margin-top: 1px; /* Espaço entre a imagem e o rótulo */
    font-size: 12px;
    font-weight: bold;
    color: #33333380;
  }
  
  .infopen-link {
    text-decoration: none; /* Remove o sublinhado */
    color: inherit; /* Herda a cor do elemento pai. Você pode definir uma cor específica, se preferir */
    cursor: pointer; /* Define o cursor como uma mãozinha ao passar o mouse */
    transition: color 0.3s; /* Transição suave para a cor */
  }
  
  .infopen-link:hover {
    color: #ff6a8a; /* Define uma cor diferente ao passar o mouse */
  }

  .title-listagem {
    font-size: 2.5rem; /* Tamanho grande para destaque */
    font-weight: bold; /* Negrito */
    color: #939292; /* Cor escura para contraste */
    text-align: center; /* Centralizar o título */
    text-transform: uppercase; /* Letras maiúsculas */
    margin: 20px 0; /* Espaçamento superior e inferior */
    letter-spacing: 1px; /* Espaçamento entre letras para melhorar a leitura */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.063); /* Sombra suave para profundidade */
    margin-top: 120px;
    
}

@media (max-width: 768px) {
    .title-listagem {
        font-size: 2rem; /* Tamanho menor em telas pequenas */
    }
}

.btnAction {
  padding: 6px 6px; /* Espaçamento interno do botão */
  background-color: #707070; /* Cor de fundo */
  color: white; /* Cor do texto */
  border: none; /* Remove a borda padrão */
  border-radius: 4px; /* Arredonda os cantos */
  margin-right: 8px; /* Espaçamento entre os botões */
  font-size: 12px; /* Tamanho da fonte */
  cursor: pointer; /* Muda o cursor para o de pointer (mãozinha) */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Suaviza o hover */
  width: 38px;
}

.btnAction:hover {
  background-color: #253221; /* Cor de fundo ao passar o mouse */
  transform: scale(1.05); /* Aumenta levemente o botão no hover */
}

.btnAction:active {
  transform: scale(0.98); /* Reduz levemente o botão ao clicar */
}

.btnAction:focus {
  outline: none; /* Remove o contorno de foco padrão */
  box-shadow: 0 0 0 2px #9c9a9a; /* Adiciona um contorno suave no foco */
}

/* Estilização da barra de filtragem */


/* Estilo aprimorado da barra de busca e filtros */
.filter-bar {
  display: flex;
  gap: 15px;
  margin-bottom: -30px;
  padding: 20px;
  background-color: #f5f5f5cb; /* Cor de fundo para destaque */
  border-radius: 8px; /* Arredondamento das bordas */
  justify-content: center;
  align-items: center;
}

.filter-bar input {
  width: 200px;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.filter-bar input:focus {
  border-color: #008866; /* Cor personalizada ao focar no campo */
  box-shadow: 0px 0px 8px rgba(1, 79, 18, 0.4); /* Sombra ao focar */
  outline: none;
}

.filter-bar button {
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  background-color: #006a88; /* Cor de fundo mais chamativa */
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.filter-bar button:hover {
  background-color: #004f66;
  transform: scale(1.05); /* Efeito hover */
}

.filter-bar button:focus {
  outline: none;
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
  .filter-bar {
    flex-direction: column;
    gap: 15px;
  }

  .filter-bar input, 
  .filter-bar button {
    width: 100%;
  }
}
.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1500px;
  height: 70vh; /* Faz com que ocupe a altura total da tela */
  font-family: 'Arial', sans-serif; /* Fonte limpa e profissional */
  color: #555; /* Cor de texto suave */
  font-size: 16px; /* Tamanho da fonte */
}

.no-data td {
  padding: 20px; /* Espaçamento interno */
  text-align: center;
  font-weight: bold; /* Texto em negrito */
  border-radius: 8px; /* Bordas arredondadas */
  background-color: #ffffff; /* Fundo branco para destacar a mensagem */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.no-data td:hover {
  transition: background-color 0.3s ease; /* Transição suave */
}
