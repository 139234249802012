.skeleton-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Centraliza verticalmente */
    gap: 25px; /* Espaçamento entre os skeletons */
  }
  
  .skeleton-select {
    width: 100%;  /* Mesma largura do select original */
    max-width: 1100px;
    height: 50px;  /* Altura semelhante ao select original */
    background-color: #e0e0e0;
    border-radius: 5px;
    animation: skeleton-loading 1.5s infinite ease-in-out;
    margin-bottom: 20;
    margin-top: -40;
  }
  
  .skeleton-container {
    display: flex;
    gap: 30px; /* Espaçamento entre os gráficos */
  }
  
  .skeleton-chart {
    width: 400px;
    height: 400px;
    background-color: #f0f0f0;
    border-radius: 50%;
    animation: skeleton-loading 1.5s infinite ease-in-out;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
  