@media only screen and (max-width: 768px) {
    .divLogo2 {
      
      display: fixed;
      
    }
  
  }
  @media only screen and (min-width: 768px) {
    .divLogo2 {
      display: none;
      
    }
  
  }
  @media only screen and (max-width: 768px) {
    .divLogo11 {
        display: none;
      
      
    }
  
  }
  @media only screen and (min-width: 768px) {
    .divLogo11 {
        display: fixed;
        width: '80%';
        
    }
  
  }

  /* Estilos do fundo e animação */


/* Animação de gradiente */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

/* Estilos para os caracteres animados */
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, rgb(76, 85, 73), rgb(75, 88, 71));
  color: #d3d3d3; /* Cinza suave */  background-size: 400% 400%;  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.title {
  
    margin: 0;
    font-size: 40px;
    margin-right: 10px; /* Espaço entre o texto e a logo */
    margin-left: 10px;
    margin-bottom: 5px;
    font-family: 'Lato', sans-serif;
    color: #1b1a1a; /* Texto preto */
    letter-spacing: 3px;
    /* Borda dourada ao redor do texto */
    text-shadow: 
      -1px -1px 0 #edc8a6,  
       1px -1px 0 #edc8a6,
      -1px  1px 0 #edc8a6,
       1px  1px 0 #edc8a6;

}

.form {
  width: 330px;
  background-color: rgb(33, 45, 29);
  padding: 2.2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5), 0 8px 16px rgba(0, 0, 0, 0.3);
}

.formGroup {
  margin-bottom: 1.5rem;
}

.label {
  margin-bottom: 0.9rem;
  color: #8a9175;
  font-size: 0.9rem;
}

.input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 4px;
}

.button {
  width: 100%;
  padding: 1rem;
  font-size: 0.7rem;
  background-color: #545f34;
  color: #b1b5b1b5;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-weight: bold;
}

.button:hover {
  background-color: #383f23;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  color: #4ca631;
}

.button:active {
  background-color: #6d735a;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.logo {
  width: 90px;
  height: auto;
}

.logoBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.inputCode {
  width: 200px;
  padding: 10px;
  font-size: 24px;
  text-align: center;
  letter-spacing: 8px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  background-color: #f9f9f9;
  font-family: monospace;
}
