/* src/styles/Main.css */

.cadastro-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 140px); /* Ajuste considerando o header e footer */
    width: 50%;
    position: inherit;
    flex-direction: column;
    overflow: hidden; /* Impede que a barra de rolagem seja exibida */

   
    
   
  }

  .cadastro-container::-webkit-scrollbar {
    display: none; /* Oculta a barra de rolagem */
  }
  
  .cadastro-box {
    padding: 30px;
    border-radius: 8px;
    width: 100%;
    max-width: 780px;
    overflow: hidden; /* Impede que a barra de rolagem seja exibida */
margin-top: -30px;
    
  }
  
  .input-group {
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-size: 14px;
  }
  
  .input-group input[type="text"],
  .input-group input[type="number"],
  .input-group select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .input-group .image-upload {
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  
  .input-group .image-upload .upload-label {
    display: block;
    cursor: pointer;
    color: #8a9175;
  }
  
  .input-group .image-upload .image-preview {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    border: 2px solid #ccc; /* Adiciona uma borda */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra */
  }
  
  
  button {
    width: 20%;
    padding: 12px;
    background-color: #707070;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #8a9175;
  }
  
  h1 {
    font-size: 2.5rem; /* Tamanho da fonte maior para destacar o título */
    color: #d3d3d3; /* Tom de cinza claro */
    text-align: center; /* Centraliza o título */
    font-weight: 600; /* Dá um peso médio à fonte */
    letter-spacing: 1.5px; /* Espaçamento entre letras para um efeito moderno */
    margin-top: 10px; /* Espaçamento superior */
    text-transform: uppercase; /* Deixa o texto em maiúsculas */
    font-family: 'Arial', sans-serif; /* Fonte moderna e legível */
  }
  
  .advogado-input-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Espaçamento entre as linhas de advogados */
  }
  
  .advogado-input-group input {
    margin-right: 10px; /* Espaçamento entre os inputs */
    flex: 1; /* Faz os inputs ocuparem o mesmo espaço disponível */
  }
  
  .advogado-input-group input:last-child {
    margin-right: 0; /* Remove a margem do último input */
    justify-content: center;
  }
  
  .add-advogado-button {
    margin-top: 10px; /* Espaçamento acima do botão de adicionar */
    padding: 10px;
    background-color: #707070;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 20%;
  }
  
  .add-advogado-button:hover {
    background-color: #8a9175;
  }

  .remove-advogado-button {
   
   
    background-color: #e2122e;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 40px;
    position: static;
    padding: 10px;
    
  }
  
  .remove-advogado-button:hover {
    background-color: #a94f54;
  }
  

  .preso-input-group {
    display: flex;
    gap: 10px; /* Espaçamento entre os campos */
    margin-bottom: 10px;
  }
  
  .preso-input-group input {
    flex: 1; /* Faz com que os inputs ocupem o mesmo espaço */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  
  
  .add-preso-button {
    margin-top: 10px; /* Espaçamento acima do botão de adicionar */
    padding: 10px;
    background-color: #253221;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .linha {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1); /* Cor suave */
    margin: 20px 0;
    
  }
  
  .linha-container {
    padding: 30px;
  }
  
  .periculosidadeBox{
background-color: #333;
width: 100%;
height: 80px;

background-color: #fff;

box-shadow: 0 4px 8px rgba(0, 0, 0, 0.313);
border-radius: 8px;
width: 100%;

margin-top: 20px;
justify-content: center;

  }

  checkbox-grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .dynamic-div {
    width: 100%;
    height: 35px;
    text-align: center;
    font-weight: bold;
    border: 1px solid #ccc;
    transition: background-color 0.3s ease;
    justify-items: center;
    align-content: center;
    vertical-align: middle;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.167);
   
  }
  
  .checkbox-grid {
  display: grid;
  gap: 18px;
  width: 100%;
  grid-template-columns: repeat(1, 1fr); /* 3 colunas */
  justify-content: center;
  align-items: center;
  align-content:center;
  font-size: 16;
  
}

.checkbox-item {
  display: flex;
  align-items: flex-start; /* Alinha o checkbox e o texto no início da linha */
  vertical-align: middle;
  white-space: normal;
  overflow-wrap: break-word;
}

.checkbox-item input {
  margin: 0;
  margin-top: 3px; /* Ajusta o alinhamento vertical do checkbox com o texto */
}

.checkbox-item label {
  flex: 1;
  line-height: 1.5; /* Ajusta a altura da linha para melhor alinhamento */
}


  .linha-fraca {
    border: none; /* Remove a borda padrão */
    border-top: 1px dashed #cccccc; /* Adiciona uma linha tracejada clara */
    margin: 20px 0; /* Ajusta o espaço acima e abaixo da linha */
    width: 100%; /* Faz com que a linha ocupe a largura total do contêiner */
  }

  .input-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc; /* Ajuste a borda conforme necessário */
    border-radius: 4px; /* Ajuste o arredondamento conforme necessário */
    overflow: hidden;
    width: 100%; /* Ajuste a largura conforme necessário */
  }
  
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0; /* Cor de fundo da caixa do ícone */
    padding: 5px; /* Ajuste o padding conforme necessário */
    border-right: 1px solid #ccc; /* Borda à direita do ícone */
  }
  
  .social-icon {
    width: 20px; /* Ajuste o tamanho do ícone */
    height: 20px; /* Ajuste o tamanho do ícone */
  }
  
  input {
    border: none;
    padding: 8px; /* Ajuste o padding conforme necessário */
    box-sizing: border-box;
  }
  
  .fixed-box {
    position: fixed;
    bottom: 50px;
    right: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.039);
    z-index: 1000;
    display: flex;
    align-items: center;
    border: 1px solid #2532213c; /* Contorno colorido do card */
  }
  
  .fixed-box .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #ff3db2; /* Contorno colorido da imagem */
    margin-right: 15px;
  }
  
  .fixed-box .card-content {
    display: flex;
    flex-direction: column;
  }
  
  .fixed-box .card-content .name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  .fixed-box .card-content .titulo {
    font-size: 12px;
    font-weight: bold;
    color: #69686874;
    letter-spacing: 2px;
    margin-bottom: 1px;
  }
  
  .fixed-box .card-content .score {
    font-size: 16px;
    color: #006a88;
  }
  
  .titulo{
    font-size: 12px;
    font-weight: bold;
    color: #69686874;
    letter-spacing: 2px;
    margin-bottom: 1px;
  }

  /* Estilos para o container das abas */
.tabs {
  display: flex;
  justify-content: center;
 margin-bottom: -109px;
  margin-top: 110px;
}

/* Estilos para cada botão de aba */
.tabs button {
  background-color: #fff;
  border: none;
  outline: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin: 0 5px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.313);
}

/* Estilos quando a aba está ativa */
.tabs button.active-tab {
  background-color: #7a7d7e;
  color: white;
  border-bottom: 2px solid #006a88;
}

/* Estilos quando o mouse passa por cima dos botões */
.tabs button:hover {
  background-color: #ddd;
}

/* Estilos para o conteúdo das abas */
.tab-content {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Estilos para o modal */
/* Estilos para o modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Fundo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Estilo para o conteúdo do modal com rolagem */
.modal-content {
  padding: 40px;
      overflow: hidden; /* Impede que a barra de rolagem seja exibida */

  max-width: 850px;
  width: 100%;
  max-height: 90vh; /* Limita a altura do modal para não ultrapassar a tela */
  overflow-y: auto; /* Permite rolagem vertical quando o conteúdo excede a altura */
  position: relative;
}
.modal-content::-webkit-scrollbar {
  display: none; /* Oculta a barra de rolagem */
}

/* Botão de fechar o modal */
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e2122e;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
}

.modal-close-button:hover {
  background-color: #a94f54;
}
