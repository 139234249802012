.header {
    width: 100%;
    padding: 12px;
    background-color: #253221;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .header-content {
    display: flex;
    align-items: center;
  }
  
  .header-logo {
    width: 35px; /* ajuste de tamanho conforme necessário */
    
    margin-left: 10px;
  }
  
  h1 {
    margin: 0;
    font-size: 24px; /* ajuste de tamanho conforme necessário */
  }
  
  
  .header h1 {
    margin: 0;
    font-size: 34px;
    margin-right: 10px; /* Espaço entre o texto e a logo */
    margin-left: 10px;
    font-family: 'Lato', sans-serif;
    color: #212020; /* Texto preto */
    letter-spacing: 3px;
    /* Borda dourada ao redor do texto */
    text-shadow: 
      -1px -1px 0 #edc8a6,  
       1px -1px 0 #edc8a6,
      -1px  1px 0 #edc8a6,
       1px  1px 0 #edc8a6;
}
  .header h5 {
    margin: 0;
    font-size: 12 px;
    margin-right: 10px; /* Espaço entre o texto e a logo */
    margin-left: 10px;
    color: #f6bd97; /* Texto preto */
    letter-spacing: 3px;
    /* Borda dourada ao redor do texto */
    text-shadow: 
      -1px -1px 0 #312f2e63,  
       1px -1px 0 #3f3e3e9b,
      -1px  1px 0 #363534ab,
       1px  1px 0 #1615155f;
}

  
.user-info {
  margin: 10px;
  margin-right: 30px;
  margin-top: 20px;
  text-align: right; /* Alinha à direita o bloco de informações do usuário */
}

.user-info h5 {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 0;
  color: #f6bd97; /* Ajuste da cor conforme necessário */
  text-shadow: 
    -1px -1px 0 #312f2e63,  
     1px -1px 0 #3f3e3e9b,
    -1px  1px 0 #363534ab,
     1px  1px 0 #1615155f;
}

.user-info p.additional-text {
  margin: 5px 0 0 0;
  font-size: 12px;
  color: #ccc; /* Cor do texto adicional */
}
