.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  margin-top: 50px;
}

.dashboard-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.charts-container {
  display: flex;
  justify-content: space-around; /* Permite que os gráficos se expandam */
  gap: 30px;
  width: 100%;
}

.chart {
  flex: 1;
  padding: 20px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 600px; /* Aumentar o tamanho máximo dos gráficos */
  height: 600px;    /* Aumentar a altura dos gráficos */
}

.chart canvas {
  max-width: 100%;
  max-height: 100%; /* Certifique-se de que o gráfico ocupe todo o espaço disponível */
}

.loading {
  font-size: 2rem;
  color: #006a88;
  text-align: center;
  padding: 50px;
}
