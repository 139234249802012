/* Sidebar.css */
.sidebar {
    width: 130px;
    height: 100vh;
    background: linear-gradient(180deg, #cdceca, #8b8a8a); /* Degradê de cinza para branco */
    padding: 20px;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.37);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    border: 1px solid #3d3c3c2b;

}

.sidebar nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    
    margin-top: 100px;
}

.sidebar nav ul li {
    margin-bottom: 20px;
    
}

.sidebar nav ul li:last-child {
    margin-bottom: 0;
}

.sidebar nav ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    font-size: 14px;
    color: #a7a79f; /* Cor do texto */
    background-color: #4a4848; /* Fundo dos botões */
    text-decoration: none;
    transition: all 0.3s ease;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.401); /* Sombra sutil */
    position: relative;
    letter-spacing: 1px;

    
}

.sidebar nav ul li a:hover {
    background-color: #2a2929; /* Fundo dos botões ao passar o mouse */
    color: #8a9175; /* Cor do texto ao passar o mouse */
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra ao passar o mouse */
}

.sidebar nav ul li a:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Sombra ao clicar */
}

.sidebar nav ul li a:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(60, 60, 60, 0.6); /* Realce ao focar */
}

.sidebar nav ul li a span {
    margin-left: 10px;
}

.sidebar nav ul li a svg {
    vertical-align: middle;
    margin-right: 8px;
}

/* Adiciona uma linha de destaque na parte inferior dos botões */
.sidebar nav ul li a::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 0;
    height: 3px;
    background-color: #333333; /* Cor da linha de destaque */
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.sidebar nav ul li a:hover::after {
    width: 80%;
    
}
