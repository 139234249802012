.main-content {
  display: flex;
  flex: 1;
  background-color: #f0f2f5;

}

.content-with-sidebar {
  margin-left: auto;
  padding: 20px;
  width: 100%;
  max-width: calc(100% - 250px); /* Ajuste para o sidebar */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
