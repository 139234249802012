

.modal-overlay.show {
  display: block;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  background-color: #0000004f;
  margin-top: -26px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.modal-container {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 750px;
  margin: 50px auto;
  z-index: 1001;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Suaviza o hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 100px;
}


.close-button {
  position: absolute;
  top: 10px;
  right: 1px;
  


  padding: 6px 6px; /* Espaçamento interno do botão */
  background-color: #7070703d; /* Cor de fundo */
  color: white; /* Cor do texto */
  border: none; /* Remove a borda padrão */
  border-radius: 4px; /* Arredonda os cantos */
  margin-right: 8px; /* Espaçamento entre os botões */
  font-size: 12px; /* Tamanho da fonte */
  cursor: pointer; /* Muda o cursor para o de pointer (mãozinha) */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Suaviza o hover */
  width: 38px;
}

.bar-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  
  .bar {
    position: relative;
    width: 80%; /* O mesmo tamanho da imagem */
    height: 20px;
    background-color: #e0e0e0; /* Cor da barra */
    border-radius: 15px;
    
  }
  
  .oval {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    background-color: #333536; /* Cor do oval */
    border-radius: 50%; /* Forma oval */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .number {
    font-size: 18px;
    color: white;
    font-weight: bold;
  }
  
  .ficha-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    margin: auto;
}

.ficha-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.ficha-item label {
    font-weight: bold;
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
}

.ficha-item input {
    padding: 10px;
    font-size: 14px;
    color: #555;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease-in-out;
}
.ficha-item textarea {
    padding: 10px;
    font-size: 14px;
    color: #555;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease-in-out;
}

.ficha-item2 textarea {
  padding: 10px;
  font-size: 14px;
  color: #555;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease-in-out;
  width: "100%";
}
.ficha-item input:focus {
    border-color: #006a88;
    outline: none;
}

.ficha-container {
    margin-top: 30px;
}

@media (max-width: 768px) {
    .ficha-container {
        grid-template-columns: 1fr;
    }
}

/* Estilo para os botões de tabulação */
.tabs-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  gap: 1px;
}

.tab-button {
  padding: 10px 20px;
  background-color: #253221;
  color: #fff;
  border: none;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.tab-button:hover {
  background-color: #545f34;
}

.tab-button.active {
  background-color: #8a9175;
  font-weight: bold;
}

/* Estilo para o conteúdo das tabs */
.tab-content {
  display: none;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tab-content.active {
  display: block;
}

/* Estilo da ficha de cadastro permanece o mesmo */
.ficha-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: auto;
}

.ficha-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.ficha-item label {
  font-weight: bold;
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
}

.ficha-item input {
  padding: 10px;
  font-size: 14px;
  color: #555;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease-in-out;
}

.ficha-item input:focus {
  border-color: #006a88;
  outline: none;
}

